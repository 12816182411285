import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li>
        <Link to="/">Home</Link>
      </li>
      <li className="has-droupdown">
        <Link to="/products">Products</Link>
        <ul className="submenu">
          <li>
            <Link to="/products/jobofgettingajob">
              The Job of Getting a Job
            </Link>
          </li>
          <li>
            <Link to="/products/workforcebasics">Workforce Basics</Link>
          </li>
          <li>
            <Link to="/products/healtycooking">Healthy Cooking Basics</Link>
          </li>
          <li>
            <Link to="/products/safepolice">Safety and Police Encounters</Link>
          </li>
        </ul>
      </li>
      <li className="has-droupdown">
        <Link to="/gettingstarted">Getting Started</Link>
        <ul className="submenu">
          <li>
            <Link to="/getting-started/1">Live Groups</Link>
          </li>
          <li>
            <Link to="/getting-started/2">Virtual Courses</Link>
          </li>
          <li>
            <Link to="/getting-started/3">Independent Study</Link>
          </li>
          <li>
            <Link to="/getting-started/4">One-On-One</Link>
          </li>
          <li>
            <Link to="/getting-started/5">Waitlist Engagement</Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to="/about-us">About Us</Link>
      </li>
      <li>
        <Link to="/content-creation">Content Creation</Link>
      </li>
      <li>
        <Link to="/contact">Contact</Link>
      </li>
    </ul>
  );
};
export default Nav;
