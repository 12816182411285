import React from "react";
import SEO from "../../common/SEO";
import HeaderTwo from "../../common/header/HeaderTwo";
import FooterFour from "../../common/footer/FooterThreeAlt";
import TabSPE from "../../elements/tab/TabSPE";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import ServiceGrantStudent from "../../elements/service/ServiceGrantStudent";
import { FiArrowRight } from "react-icons/fi";
import Modal from "../../elements/modal/Modal";
import { useState } from "react";
import MainSignUp from "../../elements/forms/PoliceSignUp";
import PricingThree from "../../elements/pricing/PricingTwo";
import AboutFour from "../../elements/about/AboutFour"
import Brand from "../../elements/brand/BrandPolice";

const SafePolice = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  function showModal() {
    setModalOpen(true);
  }
  
  function closeModal() {
    setModalOpen(false);
  }
  
  return (
    <>
      <SEO title="LearningQ | Safety and Police Encounters" />
      <main className="page-wrapper">
        <HeaderTwo btnStyle="btn-small" headerStyle="header-transparent" />
        {/* Start Slider Area  */}
        <div>
          <div
            className="container bg_image bg_image_fixed"
            data-black-overlay="8"
            style={{
              maxWidth: "100%",
              padding: "30px",
              margin: "0",
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/police.jpg)`,
            }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="order-2 order-lg-2 col-lg-5">
                  <div
                    className="thumbnail"
                    data-black-overlay="1"
                    style={{
                      padding: "80px 60px",
                      color: "white",
                    }}
                  >
                    <p style={{ color: "white" }}>
                      We offer a modern approach to learning better ways to interact safely and confidently with law enforcement. Developed with the IDD community in mind, this program ensures everyone is prepared to navigate police encounters with patience, understanding, and safety.
                    </p>
                    <button className="btn-default" onClick={showModal}>
                      Get Started{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </button>
                  </div>
                </div>
                <div className="order-1 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                  <div className="inner text-left" style={{ padding: "60px 20px 20px 20px" }}>
                    <h1 className="title theme-gradient" style={{ fontSize: "69px" }}>
                      Safety and Police Encounters
                    </h1>
                    <p className="description" style={{ fontSize: "25px", lineHeight: "30px" }}>
                      A Comprehensive Certification Program<br />
                      Designed for the IDD Community
                    </p>
                    <p className="description" style={{ fontSize: "25px", lineHeight: "30px", color:"white"}}>
                      Courses Start February 2025
                    </p>
                    <div className="button-group mt--30 mt_sm--20"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="How It Works"
                  description="Our certification program is designed for ease, engagement, and effectiveness, guiding you from foundational skills to full certification."
                />
              </div>
            </div>
            <ServiceGrantStudent
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <TabSPE
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
            <div className="row" style={{ marginTop: "30px" }}>
              <div className="col-lg-12">
                <a className="btn-default" href="/safety.pdf" target="_blank" rel="noopener noreferrer">
                  Download Certification Info
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}
        <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--35">
              <div className="col-lg-12 theme-shape">
        
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Training Created to Make a Real Difference"
                  description="This certification was created to minimize confusion and increase understanding during interactions with police."
                />
           
              <AboutFour></AboutFour>
              <Brand/>
              </div>
              </div>
              </div>
              </div>
        {/* Start Elements Area  */}
        <div className="rwt-pricing-area mb--50 rn-section-gap">
          <div className="container ">
            <div className="row mb--35">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Flexible Options for Individuals and Groups"
                  description="We offer flexible delivery options to meet the diverse needs of our learners and the organizations that support them. Choose the format that works best for you or your group."
                />
              </div>
            </div>
            <PricingThree />
          </div>
        </div>
        {/* End Elements Area  */}
      

        <FooterFour />
      </main>
      <Modal isOpen={isModalOpen} title="Grant Sign Up" onClose={closeModal}>
        <MainSignUp />
      </Modal>
    </>
  );
};

export default SafePolice;
